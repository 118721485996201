import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import '../styles/Header.css';
import logo from '../assets/logo.png';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
};
    

  return (
    <header className="main-header">
      <div className="header-container">
        <div className="header-left">
          <img src={logo} alt="Logo" className="header-logo" />          
          <h1 className="app-name-mobile">File Search Tool</h1>
        </div>
        <div className={`header-center ${!isAuthenticated ? 'authentication-header' : ''}`}>
          <h1 className="app-name-full">File Search Tool</h1>
        </div>
        <div className="header-right">         
          <nav className="navbar">                                    
            <div className="navbar-toggle" onClick={handleToggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            
            <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
                <ul>
                    {isAuthenticated && (
                      <>
                        {menuOpen && <li onClick={closeMenu}>X</li>}                        
                        <li onClick={() => { navigate('/'); setMenuOpen(false); }}>Home</li>
                        {/* <li onClick={() => { navigate('/multiple-search'); setMenuOpen(false); }}>Multiple Search</li> */}
                        <li onClick={() => { navigate('/lender-groups'); setMenuOpen(false); }}>Lender Groups</li>
                        <li onClick={() => { navigate('/change-password'); setMenuOpen(false); }}>Change Password</li>
                        <li onClick={() => { handleLogout(); setMenuOpen(false); }}>Logout</li> 
                      </>
                    )}                    
                </ul>
            </div>
          </nav>

        </div>
      </div>
    </header>
  );
};

export default Header;
