import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './authContext';
import Header from './components/Header';
import Home from './components/Home';
import MultipleSearch from './components/MultipleSearch';
import LenderGroups from './components/LenderGroups';
import Login from './components/Login';
import ChangePassword from './components/ChangePassword';
import './styles/App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/multiple-search" element={<ProtectedRoute><MultipleSearch /></ProtectedRoute>} />
            <Route path="/lender-groups" element={<ProtectedRoute><LenderGroups /></ProtectedRoute>} />
            <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

// Example ProtectedRoute to ensure user is logged in
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Login />;
  }
  return children;
};

export default App;
