import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/LenderGroups.css';

const LenderGroups = () => {
  const [parentGroups, setParentGroups] = useState([]);
  const [selectedParent, setSelectedParent] = useState('');
  const [newLender, setNewLender] = useState('');
  const [newParent, setNewParent] = useState('');
  const [message, setMessage] = useState('');
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;

  useEffect(() => {
    fetchParentGroups();
  }, []);

  const fetchParentGroups = async () => {
    try {
      const response = await axios.get(`${axiosDomain}/api/lendergroups/parents`);
      setParentGroups(response.data);
    } catch (err) {
      console.error('Error fetching parent groups', err);
      setMessage('Error fetching parent groups');
    }
  };

  const handleAddLender = async (e) => {
    e.preventDefault();
    if (!selectedParent || !newLender) {
      setMessage("Please select a parent group and enter a lender name.");
      return;
    }
    try {
      const response = await axios.post(`${axiosDomain}/api/lendergroups/add-lender`, {
        parentname: selectedParent,
        bankname: newLender
      });
      setMessage(response.data.message);
      setNewLender('');
      fetchParentGroups();
    } catch (err) {
      console.error('Error adding lender', err);
      setMessage("Error adding lender");
    }
  };

  const handleAddParent = async (e) => {
    e.preventDefault();
    if (!newParent) {
      setMessage("Please enter a new parent group name.");
      return;
    }
    try {
      const response = await axios.post(`${axiosDomain}/api/lendergroups/add-parent`, { parentname: newParent });
      setMessage(response.data.message);
      setNewParent('');
      fetchParentGroups();
    } catch (err) {
      console.error('Error adding parent group', err);
      setMessage("Error adding parent group");
    }
  };

  return (
    <div className="lender-groups-container">
      <h1>Lender Group Management</h1>
      {message && <p className="message">{message}</p>}
      <div className="form-section">
        <h2>Add Lender to Existing Parent Group</h2>
        <form onSubmit={handleAddLender}>
          <div className="form-group">
            <label>Parent Group:</label>
            <select value={selectedParent} onChange={(e) => setSelectedParent(e.target.value)}>
              <option value="">Select a parent group</option>
              {parentGroups.map((group, index) => (
                <option key={index} value={group.parentname}>{group.parentname}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Lender Name:</label>
            <input 
              type="text" 
              value={newLender} 
              onChange={(e) => setNewLender(e.target.value)}
              placeholder="Enter lender name" 
            />
          </div>
          <button type="submit">Add Lender</button>
        </form>
      </div>
      <div className="form-section">
        <h2>Add New Parent Group</h2>
        <form onSubmit={handleAddParent}>
          <div className="form-group">
            <label>Parent Group Name:</label>
            <input 
              type="text" 
              value={newParent} 
              onChange={(e) => setNewParent(e.target.value)}
              placeholder="Enter new parent group name" 
            />
          </div>
          <button type="submit">Add Parent Group</button>
        </form>
      </div>
    </div>
  );
};

export default LenderGroups;
