import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../authContext';
import { useNavigate } from 'react-router-dom';
import '../styles/ChangePassword.css';

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${axiosDomain}/change-password`, { token, newPassword });
      setMessage('Password changed successfully.');
      setNewPassword('');
      setTimeout(() => navigate('/'), 1500);
    } catch (err) {
      setMessage('Error changing password.');
      console.error('Error:', err);
    }
  };

  if (!isAuthenticated) {
    return <div>Please login to change your password.</div>;
  }

  return (
    <div className="change-password-container">
      <h1>Change Password</h1>
      {message && <p className="success-message">{message}</p>}
      <form onSubmit={handleSubmit} className="change-password-form">
        <label>New Password:</label>
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
