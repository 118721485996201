import React, { useState, useMemo } from 'react';
import axios from 'axios';
import Select from 'react-select';
import '../styles/HomePage.css';
import DownloadIcon from '../assets/downloadIcon';
import Loader from './Loader';

const Home = () => {
  const [searchType, setSearchType] = useState('ca_reference');
  const [searchQuery, setSearchQuery] = useState('');
  const [clientDetails, setClientDetails] = useState(null);
  const [references, setReferences] = useState([]);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [selectedCARefs, setSelectedCARefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;

  const handleSearch = async (e) => {
    e.preventDefault();
    setMessage('');
    setClientDetails(null);
    setReferences([]);
    setFiles([]);

    if (!searchQuery) return;

    try {
      setIsLoading(true);
      const resp = await axios.get(`${axiosDomain}/search`, {
        params: { query: searchQuery, type: searchType }
      });
      const data = resp.data;

      if (!data.clientDetails) {
        setMessage('No matching client found.');
      } else {
        setClientDetails(data.clientDetails);
        setReferences(data.references || []);
        setFiles(data.files || []);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('Error searching:', err);
      setMessage('Error during search');
    }
  };
  
  const filteredFiles = useMemo(() => {
    let tempFiles = [...files];

    if (selectedFileTypes.length > 0) {
      const fileTypeValues = selectedFileTypes.map(option => option.value);
      tempFiles = tempFiles.filter(file => fileTypeValues.includes(file.file_type));
    }

    if (selectedCARefs.length > 0) {
      const caRefValues = selectedCARefs.map(option => option.value);
      tempFiles = tempFiles.filter(file => caRefValues.includes(file.ca_reference));
    }

    return tempFiles;
  }, [files, selectedFileTypes, selectedCARefs]);
  
  const fileTypeOptions = useMemo(() => {
    const types = [...new Set(files.map(file => file.file_type))];
    return types.map(type => ({ value: type, label: type }));
  }, [files]);

  const caRefOptions = useMemo(() => {
    const refs = [...new Set(files.map(file => file.ca_reference))];
    return refs.map(ref => ({ value: ref, label: ref }));
  }, [files]);


  if (isLoading) return <Loader />;

  return (    
    <div className="home-container">
      <center><h2>Single Case Search</h2></center>
      <form onSubmit={handleSearch} className="search-form">
        <div className="form-group">
          <label>Search Type:</label>
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="ca_reference">Case Reference</option>
            <option value="source_ref">Case ID</option>
            <option value="proclaim_ref">Proclaim ID</option>
            <option value="email">Email</option>
          </select>
        </div>
        <div className="form-group">
          <label>Query:</label>
          <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} required />
        </div>
        <div className="form-submit">
          <button type="submit">Search</button>
        </div>
      </form>

      {message && <p className="message">{message}</p>}

      {clientDetails && (
        <div className="client-info">
          <h3>Client Details</h3>
          <p><strong>Name:</strong> {clientDetails.name} {clientDetails.surname}</p>
          <p><strong>Email:</strong> {clientDetails.email}</p>
          {references.length > 0 && (
            <div className="references-table">
              <h4 className="h4-heading">Case References for This Client in the Same Lender Group</h4>
              <table>
                <thead>
                  <tr>
                    <th>CA Reference</th>
                    <th>Bank Name</th>
                  </tr>
                </thead>
                <tbody>
                  {references.map((ref, idx) => (
                    <tr key={idx}>
                      <td>{ref.ca_reference}</td>
                      <td>{ref.bankname}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {files.length > 0 && (
        <>
          {/* Filters Section */}
          <div className="filters">
            <div className="filter">
              <label><strong>Filter by File Type:</strong></label>
              <Select
                isMulti
                options={fileTypeOptions}
                value={selectedFileTypes}
                onChange={setSelectedFileTypes}
                placeholder="Select File Types"
                className="select-dropdown"
                isClearable
              />
            </div>
            <div className="filter">
              <label><strong>Filter by CA Reference:</strong></label>
              <Select
                isMulti
                options={caRefOptions}
                value={selectedCARefs}
                onChange={setSelectedCARefs}
                placeholder="Select CA References"
                className="select-dropdown"
                isClearable
              />
            </div>
          </div>

          <div className="file-results">
            <h3>Files</h3>
            <table>
              <thead>
                <tr>
                  <th className="col1">File Type</th>
                  <th className="col2">File Name</th>
                  <th className="col3">Download</th>
                </tr>
              </thead>
              <tbody>
                {filteredFiles.map((f, idx) => (
                  <tr key={idx}>
                    <td className="col1">{f.file_type}</td>
                    <td className="col2">{f.file_name}</td>
                    <td className="col3">
                      <button
                        className="download-button"
                        onClick={() => window.open(`${axiosDomain}/proxy/files/${f.file_path}`, '_blank')}
                      >
                        <DownloadIcon fill="#FFFFFF" size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
