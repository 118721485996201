import React, { useState } from 'react';
import axios from 'axios';
import '../styles/MultipleSearch.css';
import DownloadIcon from '../assets/downloadIcon';

const documentTypes = [
  "Claim Pack", "LOA", "COA", "SAR Request", "DBA", "EL",
  "LBA", "Questionnaire", "SAR Request", "SAR / DSAR", "SOT", "TnC", "WS"
];

const MultipleSearch = () => {
  const [searchType, setSearchType] = useState('ca_reference');
  const [queries, setQueries] = useState('');
  const [docType, setDocType] = useState('');
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState('');
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;

  const handleSearch = async (e) => {
    e.preventDefault();
    setMessage('');
    setResults([]);
    if (!queries.trim()) return;

    try {
      const resp = await axios.get(`${axiosDomain}/search-multiple`, {
        params: { type: searchType, queries, docType }
      });
      setResults(resp.data.files || []);
      setMessage(`Found ${resp.data.files.length || 0} files`);
    } catch (err) {
      console.error('Multiple search error:', err);
      setMessage('Error during multiple search');
    }
  };

  const handleDownloadAll = async () => {
    if (results.length === 0) return;
    try {
      const resp = await axios.post(`${axiosDomain}/download-all`, { files: results, searchQuery: queries });
      window.open(resp.data.zipUrl, '_blank');
    } catch (err) {
      console.error('Download error:', err);
    }
  };

  return (
    <div className="multiple-search-container">
      <h2>Multiple Search</h2>
      <form onSubmit={handleSearch} className="multiple-search-form">
        <div className="form-group">
          <label>Search Type:</label>
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="ca_reference">Case Reference</option>
            <option value="source_ref">Case ID</option>
            <option value="proclaim_ref">Proclaim ID</option>
            <option value="email">Email</option>
          </select>
        </div>
        <div className="form-group">
          <label>Queries (one per line):</label>
          <textarea value={queries} onChange={(e) => setQueries(e.target.value)}></textarea>
        </div>
        <div className="form-group">
          <label>Document Type (optional):</label>
          <select value={docType} onChange={(e) => setDocType(e.target.value)}>
            <option value="">Any</option>
            {documentTypes.map((doc, idx) => <option key={idx} value={doc}>{doc}</option>)}
          </select>
        </div>
        <button type="submit">Search</button>
      </form>

      {message && <p className="message">{message}</p>}

      {results.length > 0 && (
        <div className="file-results">
          <h3>Files</h3>
          <button className="download-all" onClick={handleDownloadAll}>Download All</button>
          <table>
            <thead>
              <tr>
                <th>CA Reference</th>
                <th>File Type</th>
                <th>File Name</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {results.map((f, idx) => (
                <tr key={idx}>
                  <td>{f.ca_reference || 'N/A'}</td>
                  <td>{f.file_type}</td>
                  <td>{f.file_name}</td>
                  <td>
                    <button className="download-button" onClick={() => window.open(`${axiosDomain}/proxy/files/${f.file_path}`, '_blank')}>
                      <DownloadIcon fill="#FFFFFF" size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MultipleSearch;
